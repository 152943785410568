<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            GameChanger
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to GameChanger! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Phone"
              >
                <VuePhoneNumberInput
                  v-model="userPhone"
                  show-code-on-list
                  auto-detect
                  fetch-country
                  clearable
                  :color="mycolor"
                  :valid-color="correctColor"
                  required
                  @update="resultsUserPhone = $event"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                v-if="passwordSignIn"
                #default="{ errors }"
                name="Pin Code"
                vid="password"
                rules="digits:6|required"
              >
                <b-input-group
                  prepend="Pin Code"
                  class="mt-1"
                >
                  <b-form-input
                    v-model="password"
                    name="login-password"
                    maxlength="6"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                  />
                  <b-input-group-append>
                    <b-button
                      :variant="
                        passwordFieldType === 'password'
                          ? 'outline-secondary'
                          : 'outline-primary'
                      "
                      @click="togglePasswordVisibility"
                    >
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-container>
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    type="submit"
                    block
                    :disabled="!isValid"
                    @click="signInWithPassword"
                  >
                    Sign in with PIN
                  </b-button>
                </b-col>
                <b-col v-if="!passwordSignIn">
                  <b-button
                    v-if="!passwordSignIn"
                    variant="primary"
                    block
                    :disabled="!isValid"
                    @click="validationForm"
                  >
                    Sign in with OTP
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>
          <span>New on our platform? </span>
          <b-link :to="{ name: 'signup' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import Backendless from 'backendless'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BContainer,
    BRow,
    BCol,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userPhone: '',
      resultsUserPhone: null,
      mycolor: '#F00000',
      correctColor: '#02B30A',
      password: '',
      status: '',
      passwordSignIn: false,
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isValid() {
      return this.resultsUserPhone != null
        ? this.resultsUserPhone.isValid
        : false
    },
  },
  mounted() {
  },
  methods: {
    signInWithPassword() {
      if (!this.passwordSignIn) {
        this.passwordSignIn = true
        return
      }
      Backendless.UserService.login(
        this.resultsUserPhone.formattedNumber,
        this.password,
        true,
      )
        .then(() => {
          this.$router.push({ name: 'home' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Login Faild :${e.message}`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          Backendless.APIServices.Twilio.loginWithPhoneNumber(
            this.resultsUserPhone.formattedNumber,
          )
            .then(loggedInUser => {
              this.$router.push({
                name: 'verification',
                params: { id: loggedInUser.transactionId },
              })
            })
            .catch(e => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Login Faild :${e.message}`,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
